import React, { Component } from 'react';
import CommonAreasEddit from './commonAreasEddit'
import AreasSchedule from './AreasSchedule';
import SuperCore from "../../components/core/SuperCore";
import SuperModal from "../../components/coreModal/SuperModal";
import ModalBorrar from '../modals/modalDelete';
import cogoToast from "cogo-toast";
import Request from "../../core/httpClient";
import BlackoutDates from './blackoutDates';

const request = new Request();

class CommonAreaTable extends SuperCore {
	constructor(props) {
		super(props);
		this.state = {
			dataArea: [],
			idCommon: '',
			id_espacio: 0,
			horarios: [],
			id_menu_activo: null,

			nombre: '',
			descripcion: '',
			idSubfrac: 0,
			diasPrevios: 0,
			minutosPrevios: 0,
			diasMax: 0,
			consecutivas: 0,
			aforoMax: false,
			aforo: 1,
			maXVivienda: 1,
			categoria: 0,
			fianza: 0,
			pausar: false,
			motivo: '',
			activo: false,
			anuales: '',
			mensuales: '',
			diarias: '',
			imagenArea: ''
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.idCommon !== prevState.idCommon) {

			this.getSchedules(this.state.idCommon);

			this.setMenu(0);
			this.updateScreens();
		};

		if (this.state.id_espacio !== prevState.id_espacio) {
			this.setMenu(0);
			this.updateScreens();
		};
	};

	componentDidMount() {
	}

	reload() {
		this.props.reload()
	}

	getMoneyFormat(number) {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(number)
	}

	async openModal(idModal, data) {
		await this.setMenu(0);
		this.setState({
			dataArea: data,
			id_espacio: data.id_espacio,
			id_espacio_row: data.id_espacio,
			rowType: '',
			idCommon: 0,
			// horarios:[]
			nombre: data.nombre,
			descripcion: data.descripcion,
			idSubfrac: data.id_subfraccionamiento ? data.id_subfraccionamiento : null,
			diasPrevios: data.dias_reserva,
			minutosPrevios: data.minutos_previos_reserva,
			diasMax: data.dias_maximos_reserva,
			consecutivas: data.max_reservas_consecutivas,
			aforoMax: data.max_aforo !== null ? true : false,
			aforo: data.max_aforo || 1,
			maXVivienda: data.max_reservas_aforo !== null ? data.max_reservas_aforo : 1,
			categoria: data.id_categoria,
			fianza: data.fianza,
			pausar: data.pausar,
			motivo: data.motivo || '',
			activo: data.activo,
			anuales: data.max_reservas_anual,
			mensuales: data.max_reservas_mensual,
			diarias: data.max_reservas_diaria,
			imagenArea: data.area_imagen ? data.area_imagen : ''
		});

		await this.updateScreens();
		this.superOpenModal(idModal);

	};

	openRow(type, idCommon) {
		this.setState({ rowType: type, idCommon, id_espacio_row: idCommon });
		if (this.state.idCommon === idCommon && type === this.state.rowType) {
			this.setState({ rowType: '', idCommon: 0 });
			return;
		};
	};

	async getSchedules(idCommon) {
		try {
			const data = { id: idCommon };
			const response = await request.post("/commonArea/get/schedule", data);

			if (response.commonSchedules && !response.empty) {
				response.commonSchedules.map((row, i) => {
					let bloqueos = [], dias = [], exist = false;

					if (row.bloqueos !== null) {
						bloqueos = JSON.parse(row.bloqueos);
						for (let i = 1; i < 8; i++) {
							bloqueos.map(day => {
								if (day.dia === i) {
									exist = true;
								};
							});

							if (exist) {
								dias.push({ dia: i, status: exist });
							} else {
								dias.push({ dia: i, status: exist });
							};
							exist = false;
						};
					} else {
						for (let i = 1; i < 8; i++) {
							dias.push({ dia: i, status: false });
						};
					};
					row.bloqueos = dias;
				});

				this.setState({ horarios: response.commonSchedules });
			} else {
				this.setState({ horarios: [] });
			};
		} catch (error) {
			console.log(error);
		};
	};

	async setMenu(id) {
		// se cambia null para que reconozca el cambio a el nuevo id
		// en dado caso que es estado sea el mismo
		await this.setState({ id_menu_activo: null });
		await this.setState({ id_menu_activo: id });
	};

	async beforeToClose() {
		await this.setMenu(0);
		await this.updateScreens();
	}

	updateData(json) {
		this.setState(json);
	}

	async updateScreens() {
		this.setState({
			screenDefinition: [
				<CommonAreasEddit
					subfraccionamientos={this.props.subfraccionamientos}
					area={this.state.dataArea}
					reload={this.reload.bind(this)}
					escritura={this.props.escritura}
					subLabel={this.props.subLabel}
					updateData={this.updateData.bind(this)}
				/>,
				<BlackoutDates
					area={this.state.dataArea}
					updateData={this.updateData.bind(this)} />
			]
		})
	}

	async handleSubmit(event) {
		event.preventDefault();
		try {
			this.setState({ loading: true });

			let activar = this.state.activo;
			let pausar = this.state.pausar;

			if (this.state.nombre === "") {
				cogoToast.warn("Es necesario añadir nombre antes de guardar.", { position: "bottom-right" });
				this.setState({ loading: false });
				return;
			};

			if (this.state.aforoMax) {
				if (this.state.maXVivienda > this.state.aforo) {
					cogoToast.warn("El número máximo por vivienda no puede ser mayor al número de aforo máximo.", {
						position: "bottom-right",
					});
					this.setState({ loading: false });
					return;
				};
			};

			if (activar === true || activar === 1) {
				activar = 1;
			} else {
				activar = 0;
			};

			if (pausar === true || pausar === 1) {
				pausar = 1;
			} else {
				pausar = 0;
			};

			const data = {
				nombre: this.state.nombre,
				descripcion: this.state.descripcion,
				activo: activar,
				id: this.state.id_espacio,
				categoria: parseInt(this.state.categoria),
				diasR: parseInt(this.state.diasPrevios),
				minutosPrevios: parseInt(this.state.minutosPrevios),
				fianza: parseInt(this.state.fianza),
				diasMax: parseInt(this.state.diasMax),
				imagen: this.state.imagenArea,
				idSubfrac: this.state.idSubfrac,
				anuales: this.state.anuales,
				mensuales: this.state.mensuales,
				diarias: this.state.diarias,
				consecutivas: this.state.consecutivas,
				aforo: this.state.aforoMax ? parseInt(this.state.aforo) : null,
				reservasAforoMax: this.state.aforoMax ? parseInt(this.state.maXVivienda) : null,
				pausar: pausar,
				motivo: this.state.motivo,
				idFrac: localStorage.getItem('frac') || 0
			};

			const response = await request.post("/commonArea/update/common", data);

			if (response.updated) {
				this.setState({
					showModal: false,
					message: "",
				});
				this.closeModal("editCommon");
				this.props.reload();
				cogoToast.success("Datos de área común actualizados.", {
					position: "bottom-right",
				});
			} else {
				cogoToast.error("No se pudo editar área común.", {
					position: "bottom-right",
				});
			};
			this.setState({ loading: false });

		} catch (error) {
			console.log(error);
		};
	};

	render() {
		let areas = []
		if (this.props.commonAreas) {
			areas = this.props.commonAreas
		}
		return (
			<div className="card-table card-owners column" id="card-owners">
				<div className="row">
					<table className="full">
						<thead className="sticky-th">

							<tr className="text-left">
								<th className="text-left">
									<div className="row">
										Categoría &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'nombre_categoria' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre_categoria')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre_categoria')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre_categoria')}></i>
											}
										</div>
									</div>
								</th>
								<th className="text-left">
									<div className="row">
										Nombre &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'nombre' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
											}
										</div>
									</div>
								</th>
								<th className="text-left">
									<div className="row">
										Fianza &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'fianza' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'fianza')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'fianza')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'fianza')}></i>
											}
										</div>
									</div>
								</th>
								<th className="text-left">
									<div className="row">
										Estatus &nbsp;
										<div className="align-center">
											{this.props.filterColumn.col === 'activo' ? this.props.filterColumn.filt === 'asc' ?
												<i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'activo')}></i>
												:
												<i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'activo')}></i>
												:
												<i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'activo')}></i>
											}
										</div>
									</div>
								</th>
								<th className="text-center th-options-small">Editar</th>
								<th className="text-center th-options-small">Horarios</th>
								<th className="text-center th-options-small">Eliminar</th>
							</tr>
						</thead>
						<tbody>
							{areas.slice((this.props.page - 1) * this.props.pageLength, this.props.page * this.props.pageLength).map((area, key) => (
								<React.Fragment key={key}>
									<tr className={this.state.id_espacio_row === area.id_espacio ? 'row-active' : undefined}>
										<td className="text-left">{area.nombre_categoria}</td>
										<td className="text-left">{area.nombre}</td>
										<td className="text-left">{area.fianza ? `${this.getMoneyFormat(area.fianza)}` : '0.00'}</td>
										<td className="text-left">{area.activo === 1 ? ('Activo') : ('Inactivo')}</td>
										<td className="text-left">
											<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type="button" onClick={this.openModal.bind(this, "editCommon", area)} >
												<i className="font-small fas fa-pen" />
											</button>
										</td>
										<td className="text-center">
											<button className="btn-full justify-center align-center btn-mini btn-secondary color-white" type="button" onClick={this.openRow.bind(this, "newSchedule", area.id_espacio)} >
												<i className="font-small fas fa-clock"></i>
											</button>
										</td>
										<td className="text-center">
											<button
												className="btn-full justify-center align-center btn-mini btn-secondary color-white"
												type="button"
												onClick={this.openModalDelete.bind(this, "deleteCommon", area.id_espacio)}
											>
												<i className="font-text fas fa-trash-alt " />
											</button>
										</td>
									</tr>
									{this.state.idCommon === area.id_espacio &&
										<tr className="acordeon-row">
											<td className="acordeon" colSpan="6">
												<div className="row full">
													<AreasSchedule
														id={area.id_espacio}
														horarios={this.state.horarios}
														getSchedules={this.getSchedules.bind(this)}
														escritura={this.props.escritura}
													/>
												</div>
											</td>
										</tr>
									}
								</React.Fragment>
							))}

						</tbody>
					</table>
				</div>
				<SuperModal
					id={"editCommon"}
					title={"Editar área común"}
					id_menu_activo={this.state.id_menu_activo}
					menus={["Definición", "Políticas/Bloqueo de fechas"]}
					onSubmit={this.handleSubmit.bind(this)}
					screens={this.state.screenDefinition}
					nexFooter={true}
					lblSubmit="GUARDAR"
					lblCancel="CANCELAR"
					showBtnSuccess={this.props.escritura}
					loading={this.state.loading}
					message={this.state.message}
					triggerCloseModal={this.beforeToClose.bind(this)}
					size={"static-nav"}
				>
				</SuperModal>
				<ModalBorrar
					id={this.state.id_espacio}
					delete={this.handleDelete.bind(this)}
				/>
				{/*<CommonAreasEddit 
					subfraccionamientos	=	{this.props.subfraccionamientos} 
					area				=	{this.state.dataArea} 
					reload				=	{this.reload.bind(this)} 
					escritura			=	{this.props.escritura}
					subLabel			=	{this.props.subLabel}
				/>*/}
			</div>
		);
	}

	openModalDelete(data, id_espacio) {
		this.setState({
			id_espacio: id_espacio,
		});
		let modal = document.getElementById("deleteModal");
		if (modal) {
			modal.classList.remove("hide-modal");
			modal.classList.add("show-modal");
		}
	}

	async handleDelete(id, close) {
		let user = await this.getUserInfo();
		const usuario = user.info; // Directly use the fetched user info
	
		let data = {
			id_espacio: id,
			id_usuario: usuario.id_usuario,
		};
		const res = await request.post("/commonArea/delete/area", data);
		if (res.deleted) {
			cogoToast.success("Área eliminada.", {
				position: "bottom-right",
			});
			this.props.reload();
		} else {
			cogoToast.error(res.message || "Error al eliminar área.", {
				position: "bottom-right",
			});
		}
		close();
	}

}

export default CommonAreaTable;