import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import SidebarOperacion from "../components/header/sidebarOperacion";
import CommonAreaTable from "../components/commonAreas/commonTable";
import Controls from "../components/controls/controls";
import DinamicModal from "../components/dinamicModal/dinamicModal";
import Paginador from "../components/paginador/paginador";
import Request from "../core/httpClient";
import Permissions from "../middlewares/Permissions";
import cogoToast from "cogo-toast";
import sortableData from '../helpers/sortableDataTable';
import { Consumer } from "../context";

const request = new Request();

class CommonAreas extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			commonAreas: [],
			categories: [],
			categoria: 0,
			/* Estados para el filtro generico : filterData y keys */
			filterData: [],
			keys: [],
			page: 1,
			filter: "",
			subfraccionamiento: localStorage.getItem("subfrac") || 0,
			subfraccionamientos: [],
			imageArea: "",
			idSubfrac: localStorage.getItem("subfrac") || 0,
			escritura: false,
			menu: null,
			logo: require("../img/logo-AppMosphera-r.png"),
			lectura: false,
			anuales: null,
			mensuales: null,
			diarias: null,
			consecutivas: null,
			pageLength: 20,
			direction: {},
			filterColumn: {},
			aforoMax: false,
			name: '',
			diasPrevios: 0,
			minutosPrevios: 0,
			diasDisponibles: 0,
			deposito: 0,
			descripcion: '',
			anuales: 0,
			mensuales: 0,
			diarias: 0,
			consecutivas: 0,
			motivo: '',
			pausar: false,
			submenu: '',
			maXVivienda: 1,
			subLabel: '',
			aforo: 1
		};
	};

	async componentDidMount() {
		await this.validarPermisos();
		await this.getSubmenu();
		await this.getCommonAreas();
		await this.getCategories();
		await this.getSubFraccionamientos();
	}

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuOpeName();
		if (submenu) {
			this.setState({ submenu });
		};

		const subLabel = window.localStorage.getItem('subLabel');

		if (subLabel) {
			this.setState({ subLabel });
		};
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.getCommonAreas();
		};
	};

	async validarPermisos() {
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult();
		this.setState({
			escritura: permisosInfo.escritura,
			lectura: permisosInfo.lectura,
		});
	};

	async openModal(idModal) {
		this.setState({ message: null, imageArea: "" });
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-dinamic-modal");
		}
		const areaname = document.getElementById("areaname");
		if (areaname) {
			areaname.focus();
		}

		this.setState({
			aforoMax: false,
			name: '',
			diasPrevios: 0,
			minutosPrevios: 0,
			diasDisponibles: 0,
			deposito: 0,
			descripcion: '',
			aforo: 1,
			anuales: 0,
			mensuales: 0,
			diarias: 0,
			consecutivas: 0,
			activo: false,
			motivo: '',
			pausar: false,
			maXVivienda: 1
		});
	};

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add("hide-dinamic-modal");
			modal.classList.remove("show-dinamic-modal");
		};

		this.setState({
			aforoMax: false,
			name: '',
			diasPrevios: 0,
			minutosPrevios: 0,
			diasDisponibles: 0,
			deposito: 0,
			descripcion: '',
			anuales: 0,
			mensuales: 0,
			diarias: 0,
			consecutivas: 0,
			activo: false,
			motivo: '',
			pausar: false,
			maXVivienda: 1
		});
	};

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	};

	paginatorChange(length) {
		this.setState({ pageLength: length });
		this.setPage(1);
	};

	async reload() {
		await this.getCommonAreas();
	};

	async changeFrac() {
		await this.getCommonAreas();
	};

	subChange(sub) {
		this.setState({ subfraccionamiento: sub });
	};

	async nuevaArea() {
		await this.getCategories();
		this.setState({ showModal: true });
	};

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	};

	filter(CommonAreas) {
		CommonAreas = CommonAreas.filter((com) => {
			if (com.descripcion.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else if (com.nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return CommonAreas;
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.commonAreas, this.state.direction[key]);

		this.setState({
			commonAreas: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	};

	async getSubFraccionamientos() {
		try {
			this.setState({ loadingSub: true });
			const fraccionamiento = localStorage.getItem("frac");

			if (!fraccionamiento) {
				return;
			};

			const data = { Idsub: fraccionamiento, filter: 1 };
			const response = await request.post("/admin/administracion/get/subfraccionamientos", data);

			if (response && !response.error) {
				if (response.subfraccionamiento && !response.empty) {
					this.setState({
						subfraccionamientos: response.subfraccionamiento,
						filterData: response.subfraccionamiento,
					});
				} else {
					this.setState({
						empty: true,
						message: "",
						subfraccionamientos: [],
					});
				};
			} else {
				this.setState({
					error: true,
					message: "",
				});
			}
			this.setState({ loadingSub: false });
		} catch (error) {
			console.log(error);
		};
	};

	async getCategories() {
		try {
			const data = { id: localStorage.getItem("frac") };
			const response = await request.post("/commonArea/get/categories", data);

			if (response && !response.error) {
				if (response.categories && !response.empty) {
					this.setState({ categories: response.categories });

					if (response.categories.length !== 0) {
						this.setState({ categoria: response.categories[0].id_categoria });
					};
				};
			} else {
				this.setState({
					error: true,
					message: "",
				});
			};
		} catch (error) {
			console.log(error);
		};
	};

	async handleSubmit(event) {
		event.preventDefault();
		try {
			this.setState({ loadingSave: true });

			if (this.state.name === "") {
				cogoToast.warn(
					"Es necesario añadir nombre antes de guardar.",
					{
						position: "bottom-right",
					}
				);

				this.setState({ loadingSave: false });
				return;
			};

			if (this.state.aforoMax) {
				if (this.state.maXVivienda > this.state.aforo) {
					cogoToast.warn(
						"El número máximo por vivienda no puede ser mayor al número. de aforo máximo.",
						{
							position: "bottom-right",
						}
					);

					this.setState({ loadingSave: false });
					return;
				};
			};

			let image = "";

			if (this.state.imageArea) {
				image = this.state.imageArea;
			};

			const data = {
				IdFraccionamiento: parseInt(localStorage.getItem("frac")),
				idSubfrac: parseInt(this.state.idSubfrac),
				nombre: this.state.name,
				descripcion: this.state.descripcion !== '' ? this.state.descripcion : 'Sin descripción',
				Categoria: parseInt(this.state.categoria),
				diasReserva: parseInt(this.state.diasPrevios),
				minutosPrevios: parseInt(this.state.minutosPrevios),
				Activo: this.state.activo ? 1 : 0,
				fianza: parseInt(this.state.deposito),
				diasMaximos: parseInt(this.state.diasDisponibles),
				imagen: image,
				anuales: parseInt(this.state.anuales),
				mensuales: parseInt(this.state.mensuales),
				diarias: parseInt(this.state.diarias),
				consecutivas: parseInt(this.state.consecutivas),
				aforo: this.state.aforoMax ? parseInt(this.state.aforo) : null,
				reservasAforoMax: this.state.aforoMax ? parseInt(this.state.maXVivienda) : null,
				pausar: this.state.pausar ? 1 : 0,
				motivo: this.state.motivo
			};

			const response = await request.post("/commonArea/create/new", data);

			if (response.created) {
				this.setState({
					showModal: false,
					imageArea: "",
					message: "",
					anuales: 0,
					mensuales: 0,
					diarias: 0,
					consecutivas: 0,
				});
				await this.getCommonAreas();
				this.closeModal("newCommonArea");
				cogoToast.success("Área común agregada.", {
					position: "bottom-right",
				});
			} else {
				cogoToast.error("No se pudo agregar área común.", {
					position: "bottom-right",
				});
			};

			this.setState({ loadingSave: false });
		} catch (error) {
			console.log(error);
		};
	};

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove("show-dinamic-modal");
			modal.classList.add("hide-dinamic-modal");
		};
	};

	async getCommonAreas() {
		try {
			this.setState({ loading: true });
			const idSub = this.state.subfraccionamiento;

			const data = {
				IdFraccionamiento: parseInt(localStorage.getItem("frac")),
				idSub: idSub,
			};

			const response = await request.post("/commonArea/get/areas", data);

			if (response.commonAreas) {
				response.commonAreas.map((row) => {
					if (row.bloqueos !== null) {
						row.bloqueos = JSON.parse(row.bloqueos);
					};
				});
				this.setState({ commonAreas: response.commonAreas });
			} else {
				this.setState({
					message: "",
				});
			};
			this.setState({ loading: false });
		} catch (error) {
			console.log(error);
		};
	};

	render() {
		let CommonAreas = [];

		if (Array.isArray(this.state.commonAreas)) {
			CommonAreas = this.state.commonAreas;
			CommonAreas = this.filter(CommonAreas);
		}

		return (
			<div className="admin column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header
					reload={this.reload.bind(this)}
					change={this.changeFrac.bind(this)}
					sidebar={true}
					active={"activas"}
					parent={"areas"}
					panel={"panel3"}
					nav={"operacion"}
					menu={this.state.menu != null ? this.state.menu : null}
				/>

				<div className="row">
					<div className="column" id="sidebar">
						<SidebarOperacion />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								{this.state.lectura ?
									<React.Fragment>
										<Controls
											title={this.state.submenu}
											nuevo={this.openModal.bind(this, "newCommonArea")}
											dataSet={CommonAreas}
											setFilter={this.setFilter.bind(this)}
											subChange={this.subChange.bind(this)}
											newButton={this.state.escritura}
										/>

										{this.state.loading ? (
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
										) : (
											<CommonAreaTable
												commonAreas={CommonAreas}
												subfraccionamientos={this.state.subfraccionamientos}
												page={this.state.page}
												reload={this.reload.bind(this)}
												escritura={this.state.escritura}
												pageLength={this.state.pageLength}
												sortBy={this.sortableTable.bind(this)}
												filterColumn={this.state.filterColumn}
												subLabel={this.state.subLabel}
												getCommonAreas={this.getCommonAreas.bind(this)}
											/>
										)}
										<div className="white-space-16"></div>
										<Paginador
											pages={Math.ceil(CommonAreas.length / this.state.pageLength)}
											setPage={this.setPage.bind(this)}
											pageLength={this.state.pageLength}
											paginatorChange={this.paginatorChange.bind(this)}
											showPageLenght={true}
										/>
									</React.Fragment> :
									null
								}
							</div>
						</div>
					</div>
				</div>
				<DinamicModal
					idModal={"newCommonArea"}
					sizeModalContainer={"big"}
					title={"Añadir área común"}
					success={this.handleSubmit.bind(this)}
					showBtnSuccess={true}
					btnTextSuccess={"GUARDAR"}
					showBtnCancel={true}
					btnTextCancel={"CANCELAR"}
					loadingSave={this.state.loadingSave}
					message={this.state.message}
					closeModal={this.closeModal.bind(this)}
				>
					{this.renderNewCommonArea()}
				</DinamicModal>
			</div>
		);
	};

	showPreviewImageCommonArea() {
		let file = document.getElementById("fileImage").files[0];
		let reader = new FileReader();
		if (file) {
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				this.setState({ imageArea: reader.result });
			};
		};
	};

	renderNewCommonArea() {
		return (
			<div className="full row align-center">
				<form className="column full" id="newCommon">
					<div className="input-form-content row full align-center">
						<div className="column label-medium">
							<p><b>Nombre:</b></p>
						</div>
						<div className="column full space">
							<input
								type="text"
								name="commonArea"
								id="areaname"
								className="input input-modals"
								autoComplete="off"
								required
								maxLength="150"
								value={this.state.name}
								onChange={(e) => { this.setState({ name: e.target.value }) }}
							/>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="row full">
						<div className="column full">
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>{this.state.subLabel}:</b>
									</p>
								</div>
								<div className="column full">
									<select
										name="subfraccionamiento"
										className="input input-modals"
										disabled={(localStorage.getItem("subfrac") || 0) === 0 ? false : true}
										value={localStorage.getItem("subfrac") || 0}
										onChange={(event) => this.setState({ idSubfrac: event.target.value })}
									>
										<option value="0">TODOS</option>
										{
											this.state.subfraccionamientos.map((subfraccionamiento, key) => (
												<option value={subfraccionamiento.id_subfraccionamiento} key={key}>
													{subfraccionamiento.nombre}
												</option>
											))
										}
									</select>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Días previos:</b>
									</p>
								</div>
								<div className="column full">
									<input
										type="number"
										name="diasPrevios"
										className="input input-modals text-center"
										required
										min="0"
										autoComplete="off"
										value={this.state.diasPrevios}
										onChange={(e) => this.setState({ diasPrevios: e.target.value })}
									/>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Minutos previos:</b>
									</p>
								</div>
								<div className="column full">
									<input
										type="number"
										name="minutosPrevios"
										className="input input-modals text-center"
										required
										min="0"
										autoComplete="off"
										value={this.state.minutosPrevios}
										onChange={(e) => this.setState({ minutosPrevios: e.target.value })}
									/>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center">
								<div className="column label-duo-big">
									<p>
										<b>Días disponibles:</b>
									</p>
								</div>
								<div className="column full">
									<input
										type="number"
										name="diasMaximos"
										className="input input-modals text-center"
										required
										min="0"
										autoComplete="off"
										value={this.state.diasDisponibles}
										onChange={(e) => this.setState({ diasDisponibles: e.target.value })}
									/>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="row full">
								<div className="column full">
									<div className="input-form-content row full align-center">
										<div className="column label-duo-big">
											<p>
												<b>Reservas anuales:</b>
											</p>
										</div>
										<div className="column full">
											<input
												className="input input-modals text-center"
												type="number"
												value={this.state.anuales}
												onChange={(event) => {
													this.setState({ anuales: event.target.value });
												}}
												min="0"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="row full">
								<div className="column full">
									<div className="input-form-content row full align-center">
										<div className="column label-duo-big">
											<p>
												<b>Reservas mensuales:</b>
											</p>
										</div>
										<div className="column full">
											<input
												className="input input-modals text-center"
												type="number"
												value={this.state.mensuales}
												onChange={(event) => {
													this.setState({ mensuales: event.target.value });
												}}
												min="0"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="row full">
								<div className="column full">
									<div className="input-form-content row full align-center">
										<div className="column label-duo-big">
											<p>
												<b>Reservas diarias:</b>
											</p>
										</div>
										<div className="column full">
											<input
												className="input input-modals text-center"
												type="number"
												value={this.state.diarias}
												onChange={(event) => {
													this.setState({ diarias: event.target.value });
												}}
												min="0"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="row full">
								<div className="column full">
									<div className="input-form-content row full align-center">
										<div className="column label-duo-big">
											<p>
												<b>Reservas consecutivas:</b>
											</p>
										</div>
										<div className="column full">
											<input
												className="input input-modals text-center"
												type="number"
												value={this.state.consecutivas}
												onChange={(event) => {
													this.setState({ consecutivas: event.target.value });
												}}
												min="0"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="white-space-16"></div>
							{!this.state.aforoMax ? <div className="input-form-content row full align-center justify-start">
								<div className="row label-duo-big">
									<div className="column">
										<label className="container-check">
											<input
												type="checkbox"
												id="aforoMax"
												checked={this.state.aforoMax ? true : false}
												name="aforoMax"
												onChange={(event) =>
													this.setState({ aforoMax: event.target.checked, aforo: 1, maXVivienda: 1 })
												}
											/>
											<span className="checkmark"></span>
										</label>
									</div>
									<div className="column">
										<h5>
											<b>Aforo máximo</b>
										</h5>
									</div>
								</div>
							</div> :
								<div className="group">
									<div className="input-form-content row full align-center justify-start">
										<div className="row label-duo-big">
											<div className="column">
												<label className="container-check">
													<input
														type="checkbox"
														id="aforoMax"
														checked={this.state.aforoMax ? true : false}
														name="aforoMax"
														onChange={(event) =>
															this.setState({ aforoMax: event.target.checked, aforo: 1, maXVivienda: 1 })
														}
													/>
													<span className="checkmark"></span>
												</label>
											</div>
											<div className="column">
												<h5>
													<b>Aforo máximo</b>
												</h5>
											</div>
										</div>
										<div className="column full">
											<input
												className="input input-modals text-center"
												type="number"
												value={this.state.aforo}
												onChange={(event) => {
													this.setState({ aforo: parseInt(event.target.value) });
												}}
												min="1"
											/>
										</div>
									</div>
									<div className="white-space-8"></div>
									<div className="input-form-content row full align-center justify-start">
										<div className="row label-duo-big">
											<div className="column">
												<h5>
													<b>Máximo por vivienda</b>
												</h5>
											</div>
										</div>
										<div className="column full">
											<input
												className="input input-modals text-center"
												type="number"
												value={this.state.maXVivienda}
												onChange={(event) => {
													this.setState({ maXVivienda: parseInt(event.target.value) });
												}}
												min="1"
											/>
										</div>
									</div>
								</div>}
						</div>
						<div className="column full column-img">
							<div className="input-form-content row full align-center space-24">
								<div className="column auto">
									<p>
										<b>Categoría:</b>
									</p>
								</div>
								<div className="column full space-24">
									<select
										name="categoria"
										id=""
										className="input input-modals"
										value={this.state.categoria}
										onChange={(event) =>
											this.setState({ categoria: event.target.value })
										}>
										{this.state.categories.map((categoria, key) => (
											<option value={categoria.id_categoria} key={key}>
												{categoria.nombre}
											</option>
										))}
									</select>
								</div>
							</div>
							<div className="white-space-8"></div>
							<div className="input-form-content row full align-center space-24">
								<div className="column auto">
									<p>
										<b>Depósito:</b>
									</p>
								</div>
								<div className="column full space-24">
									<input
										type="number"
										name="fianza"
										className="input input-modals text-center"
										required
										maxLength="150"
										min={0}
										autoComplete="off"
										value={this.state.deposito}
										onChange={(e) => {
											this.setState({ deposito: e.target.value })
										}}
									/>
								</div>
							</div>
							<div className="white-space-24"></div>
							<div className="responsive-img justify-end row">
								<div className="container-img justify-center">
									<img
										src={
											this.state.imageArea
												? this.state.imageArea
												: require('../img/empty_image.png')
										}
										className="img-duo-preview"
										id="previewArea"
									/>

									<div className="middle-overlay">
										<label className="btn-upload-image column align-center justify-center">
											<i className="fas fa-file-upload"></i>
											<input
												type="file"
												id="fileImage"
												name="AreaImage"
												accept="image/*"
												onChange={this.showPreviewImageCommonArea.bind(this)}
											/>
											<div className="white-space-8"></div>
											<p className="color-white">Subir imagen</p>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="column">
						<p>
							<b>Políticas de uso:</b>
						</p>
						<div className="white-space-8"></div>
						<div className="align-center row full">
							<div className="column full">
								<textarea
									name="descripcion"
									className="textarea input input-modals"
									cols="50"
									rows="5"
									autoComplete="off"
									value={this.state.descripcion}
									onChange={(e) => {
										this.setState({ descripcion: e.target.value })
									}}
								></textarea>
							</div>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="row">
						<div className="input-form-content row auto align-center justify-start width-30">
							<div className="column">
								<label className="container-check">
									<input
										type="checkbox"
										id="pausar"
										checked={this.state.pausar ? true : false}
										name="pausado"
										onChange={(event) => this.setState({ pausar: event.target.checked })}
									/>
									<span className="checkmark"></span>
								</label>
							</div>
							<div className="column">
								<h5>
									<b>Pausar reservas</b>
								</h5>
							</div>
						</div>
						<div className="align-center row full space-16">
							<div className="column full">
								<textarea
									name="motivo"
									className="textarea textarea-small input input-modals"
									cols="50"
									rows="5"
									autoComplete="off"
									value={this.state.motivo}
									onChange={(event) => this.setState({ motivo: event.target.value })}
								></textarea>
							</div>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="input-form-content row full align-center justify-start">
						<div className="column">
							<label className="container-check">
								<input
									type="checkbox"
									id="activar"
									checked={this.state.activo ? true : false}
									name="activo"
									onChange={(event) => this.setState({ activo: event.target.checked })}
								/>
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="column">
							<h5>
								{" "}
								<b>Activar área común</b>{" "}
							</h5>
						</div>
					</div>
				</form>
			</div>
		);
	};
};

export default Consumer(CommonAreas);
