import React, { Component } from "react";
import cogoToast from "cogo-toast";

import Request from "../../core/httpClient";
const request = new Request();

class CommonAreasEddit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			nombre: this.props.area.nombre,
			descripcion: this.props.area.descripcion,
			activo: this.props.area.activo,
			id: this.props.area.id_espacio,
			categoria: this.props.area.id_categoria,
			diasPrevios: this.props.area.dias_reserva,
			minutosPrevios: this.props.area.minutos_previos_reserva,
			fianza: this.props.area.fianza,
			diasMax: this.props.area.dias_maximos_reserva,
			imagenArea: this.props.area.area_imagen
				? this.props.area.area_imagen
				: "",
			idSubfrac: this.props.area.id_subfraccionamiento
				? this.props.area.id_subfraccionamiento
				: null,
			subfraccionamientos: this.props.subfraccionamientos,
			categories: [],
			anuales: this.props.area.max_reservas_anual,
			mensuales: this.props.area.max_reservas_mensual,
			diarias: this.props.area.max_reservas_diaria,
			consecutivas: this.props.area.max_reservas_consecutivas,
			aforo: this.props.area.max_aforo || 1,
			pausar: this.props.area.pausar !== null && this.props.area.pausar !== 0 ? 1 : 0,
			motivo: this.props.area.motivo !== null && this.props.area.motivo !== '' ? this.props.area.motivo : '',
			aforoMax: this.props.area.max_aforo !== null ? true : false,
			maXVivienda: this.props.area.max_reservas_aforo !== null ? this.props.area.max_reservas_aforo : 1,
			subLabel: this.props.subLabel
		};

		this.updateData({
			nombre: this.props.area.nombre,
			descripcion: this.props.area.descripcion,
			activo: this.props.area.activo,
			id: this.props.area.id_espacio,
			categoria: this.props.area.id_categoria,
			diasPrevios: this.props.area.dias_reserva,
			minutosPrevios: this.props.area.minutos_previos_reserva,
			fianza: this.props.area.fianza,
			diasMax: this.props.area.dias_maximos_reserva,
			imagenArea: this.props.area.area_imagen,
			idSubfrac: this.props.area.id_subfraccionamiento
				? this.props.area.id_subfraccionamiento
				: null,
			anuales: this.props.area.max_reservas_anual,
			mensuales: this.props.area.max_reservas_mensual,
			diarias: this.props.area.max_reservas_diaria,
			consecutivas: this.props.area.max_reservas_consecutivas,
			aforo: this.props.area.max_aforo || 1,
			pausar: this.props.area.pausar,
			motivo: this.props.area.motivo || '',
			aforoMax: this.props.area.max_aforo !== null ? true : false,
			maXVivienda: this.props.area.max_reservas_aforo !== null ? this.props.area.max_reservas_aforo : 1,
			subLabel: this.props.subLabel,
			subfraccionamientos: this.props.subfraccionamientos,
		});

		this.getCategories();
	};

	componentDidUpdate(prevProps) {
		if (this.props.area !== prevProps.area) {
			this.setState({
				nombre: this.props.area.nombre,
				descripcion: this.props.area.descripcion,
				activo: this.props.area.activo,
				id: this.props.area.id_espacio,
				categoria: this.props.area.id_categoria,
				diasPrevios: this.props.area.dias_reserva,
				minutosPrevios: this.props.area.minutos_previos_reserva,
				fianza: this.props.area.fianza,
				diasMax: this.props.area.dias_maximos_reserva,
				imagenArea: this.props.area.area_imagen,
				idSubfrac: this.props.area.id_subfraccionamiento
					? this.props.area.id_subfraccionamiento
					: null,
				anuales: this.props.area.max_reservas_anual,
				mensuales: this.props.area.max_reservas_mensual,
				diarias: this.props.area.max_reservas_diaria,
				consecutivas: this.props.area.max_reservas_consecutivas,
				aforo: this.props.area.max_aforo || 1,
				pausar: this.props.area.pausar,
				motivo: this.props.area.motivo || '',
				aforoMax: this.props.area.max_aforo !== null ? true : false,
				maXVivienda: this.props.area.max_reservas_aforo !== null ? this.props.area.max_reservas_aforo : 1,
				subLabel: this.props.subLabel,
				subfraccionamientos: this.props.subfraccionamientos,
			});
			if (this.props.area.area_imagen) {
				if (this.props.area.area_imagen.indexOf('data:image/') === 0) {
					const data = {
						area_imagen: this.props.area.area_imagen,
						id_area: this.props.area.id_espacio
					}
					this.base64ToUrl(data);
				}
			}
			if (this.props.area.id_subfraccionamiento !== prevProps.id_subfraccionamiento) {

				this.setState({ idSubfrac: this.props.area.id_subfraccionamiento || '' });
			}
			this.getCategories();
		}
		// this.refs['input' + this.props.line ].focus();
	};

	async base64ToUrl(info) {
		try {
			const data = {
				img: info.area_imagen,
				idfrac: localStorage.getItem('frac') || 0,
				id: info.id_area,
				module: "areasComunes"
			}

			const response = await request.post("/commonArea/base64ToUrl", data);

			if (response) {

				if (!response.error) {
					if (info.type === 1) {
						this.setState({ eine1: response.url });
					};

					if (info.type === 2) {
						this.setState({ eine2: response.url });
					};
				};
			};
		} catch (error) {
			console.log(error);
		};
	};

	async closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove("show-dinamic-modal");
			modal.classList.add("hide-dinamic-modal");
		}

		this.setState({
			nombre: this.props.area.nombre,
			descripcion: this.props.area.descripcion,
			activo: this.props.area.activo,
			id: this.props.area.id_espacio,
			categoria: this.props.area.id_categoria,
			diasPrevios: this.props.area.dias_reserva,
			minutosPrevios: this.props.area.minutos_previos_reserva,
			fianza: this.props.area.fianza,
			diasMax: this.props.area.dias_maximos_reserva,
			imagenArea: this.props.area.area_imagen,
			idSubfrac: this.props.area.id_subfraccionamiento
				? this.props.area.id_subfraccionamiento
				: null,
			anuales: this.props.area.max_reservas_anual,
			mensuales: this.props.area.max_reservas_mensual,
			diarias: this.props.area.max_reservas_diaria,
			consecutivas: this.props.area.max_reservas_consecutivas,
			aforo: this.props.area.max_aforo || 1,
			pausar: this.props.area.pausar,
			motivo: this.props.area.motivo || '',
			aforoMax: this.props.area.max_aforo !== null ? true : false,
			maXVivienda: this.props.area.max_reservas_aforo !== null ? this.props.area.max_reservas_aforo : 1,
			subfraccionamientos: this.props.subfraccionamientos,
		});
	};

	showPreviewImageCommon() {
		let file = document.getElementById("fileImageEdit").files[0];
		let reader = new FileReader();
		if (file) {
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				this.setState({ imagenArea: reader.result });
				this.updateData({ imagenArea: reader.result });
			};
		};
	};

	async handleOpen() {
		this.setState({ showModal: true, message: "" });
		await this.getCategories();
	};

	async getCategories() {
		try {
			this.setState({ loading: true });

			const data = { id: localStorage.getItem("frac") };
			const response = await request.post("/commonArea/get/categories", data);

			if (response && !response.error) {
				if (response.categories && !response.empty) {
					this.setState({ categories: response.categories });
				};
			} else {
				this.setState({
					error: true,
					message: "",
				});
			};

			this.setState({ loading: false });
		} catch (error) {
			console.log(error);
		};
	};

	async handleSubmit(event) {
		event.preventDefault();
		try {
			this.setState({ loading: true });

			let activar = document.getElementById("activar").checked;
			let pausar = document.getElementById("pausar").checked;

			if (this.state.nombre === "") {
				cogoToast.warn("Es necesario añadir nombre antes de guardar.", {
					position: "bottom-right",
				});
				this.setState({ loading: false });
				return;
			};

			if (this.state.aforoMax) {
				if (this.state.maXVivienda > this.state.aforo) {
					cogoToast.warn("El número máximo por vivienda no puede ser mayor al número de aforo máximo.", {
						position: "bottom-right",
					});
					this.setState({ loading: false });
					return;
				};
			};

			if (activar === true || activar === 1) {
				activar = 1;
			} else {
				activar = 0;
			};

			if (pausar === true || pausar === 1) {
				pausar = 1;
			} else {
				pausar = 0;
			};

			const data = {
				nombre: this.state.nombre,
				descripcion: this.state.descripcion,
				activo: activar,
				id: this.state.id,
				categoria: parseInt(this.state.categoria),
				diasR: parseInt(this.state.diasPrevios),
				minutosPrevios: parseInt(this.state.minutosPrevios),
				fianza: parseInt(this.state.fianza),
				diasMax: parseInt(this.state.diasMax),
				imagen: this.state.imagenArea,
				idSubfrac: this.state.idSubfrac,
				anuales: this.state.anuales,
				mensuales: this.state.mensuales,
				diarias: this.state.diarias,
				consecutivas: this.state.consecutivas,
				aforo: this.state.aforoMax ? parseInt(this.state.aforo) : null,
				reservasAforoMax: this.state.aforoMax ? parseInt(this.state.maXVivienda) : null,
				pausar: pausar,
				motivo: this.state.motivo,
				idFrac: localStorage.getItem('frac') || 0
			};

			const response = await request.post("/commonArea/update/common", data);

			if (response.updated) {
				this.setState({
					showModal: false,
					message: "",
				});
				this.closeModal("editCommon");
				this.props.reload();
				cogoToast.success("Datos de área común actualizados.", {
					position: "bottom-right",
				});
			} else {
				cogoToast.error("No se pudo editar área común.", {
					position: "bottom-right",
				});
			};

			this.setState({ loading: false });
		} catch (error) {
			console.log(error);
		};
	};

	handleClose() {
		this.setState({ showModal: false });
	};

	async updateData(data) {
		await this.props.updateData(data);
		this.setState(data);
	};

	render() {
		return (
			<div className="container">
				<div className="full row align-center">
					<div className="column full">
						<div className="white-space-4"></div>
						<div className="input-form-content row full align-center">
							<div className="column label-medium">
								<p>
									<b>Nombre:</b>
								</p>
							</div>
							<div className="column full space">
								<input
									className="input input-modals"
									type="text"
									name="commonArea"
									id="nedit"
									value={this.state.nombre}
									onChange={(event) =>
										this.updateData({ nombre: event.target.value })
									}
									required
									maxLength="150"
									autoComplete="off"
								/>
							</div>
						</div>
						<div className="white-space-8"></div>
						<div className="row full">
							<div className="column full">
								<div className="input-form-content row full align-center">
									<div className="column label-duo-big">
										<p>
											<b>{this.state.subLabel}:</b>
										</p>
									</div>
									<div className="column full">
										<select
											name="subfraccionamiento"
											className="input input-modals"
											value={this.state.idSubfrac}
											onChange={(event) =>
												this.updateData({ idSubfrac: event.target.value })
											}
										>
											<option value="0">TODOS</option>
											{this.state.subfraccionamientos.map(
												(subfraccionamiento, key) => (
													<option
														value={subfraccionamiento.id_subfraccionamiento}
														key={key}
													>
														{subfraccionamiento.nombre}
													</option>
												)
											)}
										</select>
									</div>
								</div>
								<div className="white-space-8"></div>
								<div className="input-form-content row full align-center">
									<div className="column label-duo-big">
										<p>
											<b>Días previos: </b>
										</p>
									</div>
									<div className="column full">
										<input
											type="number"
											value={this.state.diasPrevios}
											onChange={(event) =>
												this.updateData({ diasPrevios: event.target.value })}
											name="diasp"
											className="input input-modals text-center"
											autoComplete="off"
											min="0"
										/>
									</div>
								</div>
								<div className="white-space-8"></div>
								<div className="input-form-content row full align-center">
									<div className="column label-duo-big">
										<p>
											<b>Minutos previos: </b>
										</p>
									</div>
									<div className="column full">
										<input
											type="number"
											value={this.state.minutosPrevios}
											onChange={(event) =>
												this.updateData({ minutosPrevios: event.target.value })}
											name="diasp"
											className="input input-modals text-center"
											autoComplete="off"
											min="0"
										/>
									</div>
								</div>
								<div className="white-space-8"></div>
								<div className="input-form-content row full align-center">
									<div className="column label-duo-big">
										<p>
											<b>Días disponibles: </b>
										</p>
									</div>
									<div className="column full">
										<input
											type="number"
											value={this.state.diasMax}
											onChange={(event) =>
												this.updateData({ diasMax: event.target.value })}
											name="diasp"
											className="input input-modals text-center"
											autoComplete="off"
											min="0"
										/>
									</div>
								</div>

								<div className="white-space-8"></div>
								<div className="input-form-content row full align-center">
									<div className="column label-duo-big">
										<p>
											<b>Reservas anuales:</b>
										</p>
									</div>
									<div className="column full space">
										<input
											className="input input-modals text-center"
											type="number"
											value={this.state.anuales}
											onChange={(event) =>
												this.updateData({ anuales: event.target.value })
											}
											min="0"
										/>
									</div>
								</div>

								<div className="white-space-8"></div>
								<div className="input-form-content row full align-center">
									<div className="column label-duo-big">
										<p>
											<b>Reservas mensuales:</b>
										</p>
									</div>
									<div className="column full space">
										<input
											className="input input-modals text-center"
											type="number"
											value={this.state.mensuales}
											onChange={(event) =>
												this.updateData({ mensuales: event.target.value })
											}
											min="0"
										/>
									</div>
								</div>

								<div className="white-space-8"></div>
								<div className="input-form-content row full align-center">
									<div className="column label-duo-big">
										<p>
											<b>Reservas diarias:</b>
										</p>
									</div>
									<div className="column full space">
										<input
											className="input input-modals text-center"
											type="number"
											value={this.state.diarias}
											onChange={(event) =>
												this.updateData({ diarias: event.target.value })
											}
											min="0"
										/>
									</div>
								</div>

								<div className="white-space-8"></div>
								<div className="row full">
									<div className="column full">
										<div className="input-form-content row full align-center">
											<div className="column label-duo-big">
												<p>
													<b>Reservas consecutivas:</b>
												</p>
											</div>
											<div className="column full">
												<input
													className="input input-modals text-center"
													type="number"
													value={this.state.consecutivas}
													onChange={(event) => {
														this.updateData({ consecutivas: event.target.value })
													}}
													min="0"
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="white-space-8"></div>
								<div className="row full">
									<div className="row full">

										{!this.state.aforoMax ? <div className="input-form-content row full align-center justify-start">
											<div className="row label-duo-big">
												<div className="column">
													<label className="container-check">
														<input
															type="checkbox"
															id="aforoMax"
															checked={this.state.aforoMax ? true : false}
															name="aforoMax"
															onChange={(event) =>
																this.updateData({ aforoMax: event.target.checked, aforo: 1, maXVivienda: 1 })}
														/>
														<span className="checkmark"></span>
													</label>
												</div>
												<div className="column">
													<h5>
														<b>Aforo máximo</b>
													</h5>
												</div>
											</div>
										</div> :
											<div className="group full">
												<div className="input-form-content row full align-center justify-start">
													<div className="row label-duo-big">
														<div className="column">
															<label className="container-check">
																<input
																	type="checkbox"
																	id="aforoMax"
																	checked={this.state.aforoMax ? true : false}
																	name="aforoMax"
																	onChange={(event) =>
																		this.updateData({ aforoMax: event.target.checked, aforo: 1, maXVivienda: 1 })}
																/>
																<span className="checkmark"></span>
															</label>
														</div>
														<div className="column">
															<h5>
																<b>Aforo máximo</b>
															</h5>
														</div>
													</div>
													<div className="column full">
														<input
															className="input input-modals text-center"
															type="number"
															value={this.state.aforo}
															onChange={(event) => {
																this.updateData({ aforo: parseInt(event.target.value) })
															}}
															min="1"
														/>
													</div>
												</div>
												<div className="white-space-8"></div>
												<div className="input-form-content row full align-center justify-start">
													<div className="row label-duo-big">
														<div className="column">
															<h5>
																<b>Máximo por vivienda</b>
															</h5>
														</div>
													</div>
													<div className="column full">
														<input
															className="input input-modals text-center"
															type="number"
															value={this.state.maXVivienda}
															onChange={(event) => {
																this.updateData({ maXVivienda: parseInt(event.target.value) })
															}}
															min="1"
														/>
													</div>
												</div>
											</div>}
									</div>
								</div>
							</div>
							<div className="column full column-img">
								<div className="input-form-content row full align-center space-24">
									<div className="column auto">
										<p>
											<b>Categoría: </b>
										</p>
									</div>
									<div className="column full space-24">
										<select
											name="categoria"
											id=""
											value={this.state.categoria}
											onChange={(event) => this.updateData({ categoria: event.target.value })}
											className="input input-modals"
										>
											{this.state.categories.map((categoria, key) => (
												<option value={categoria.id_categoria} key={key}>
													{categoria.nombre}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="white-space-8"></div>
								<div className="input-form-content row full align-center space-24">
									<div className="column auto">
										<p>
											<b>Depósito:</b>
										</p>
									</div>
									<div className="column full space-24">
										<input
											className="input input-modals text-center"
											type="number"
											name="fianza"
											value={this.state.fianza}
											onChange={(event) => this.updateData({ fianza: event.target.value })}
											autoComplete="off"
										/>
									</div>
								</div>
								<div className="white-space-16"></div>
								<div className="responsive-img justify-end row">
									<div className="container-img justify-center">
										<img
											src={
												this.state.imagenArea
													? this.state.imagenArea
													: require('../../img/empty_image.png')
											}
											className="img-duo-preview"
											id="previewAreaEdit"
										/>

										<div className="middle-overlay">
											<label className="btn-upload-image column align-center justify-center">
												<i className="fas fa-file-upload"></i>
												<input
													type="file"
													id="fileImageEdit"
													name="AreaImage"
													accept="image/*"
													onChange={this.showPreviewImageCommon.bind(this)}
												/>
												<div className="white-space-8"></div>
												<p className="color-white">Subir imagen</p>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="white-space-8"></div>
						<div className="row">
							<div className="input-form-content row auto align-center justify-start width-30">
								<div className="column">
									<label className="container-check">
										<input
											type="checkbox"
											id="pausar"
											checked={this.state.pausar ? true : false}
											name="pausado"
											onChange={(event) => this.updateData({ pausar: event.target.checked })}
										/>
										<span className="checkmark"></span>
									</label>
								</div>
								<div className="column">
									<h5>
										<b>Pausar reservas</b>
									</h5>
								</div>
							</div>
							<div className="align-center row full space-16">
								<div className="column full">
									<textarea
										name="motivo"
										className="textarea textarea-small input input-modals"
										cols="50"
										rows="5"
										autoComplete="off"
										value={this.state.motivo}
										onChange={(event) => this.updateData({ motivo: event.target.value })}
									></textarea>
								</div>
							</div>
						</div>
						<div className="white-space-8"></div>
						<div className="input-form-content row full align-center justify-start">
							<div className="column">
								<label className="container-check">
									<input
										type="checkbox"
										id="activar"
										checked={this.state.activo ? true : false}
										name="activo"
										onChange={(event) => this.updateData({ activo: event.target.checked })}
									/>
									<span className="checkmark"></span>
								</label>
							</div>
							<div className="column">
								<h5>
									<b>Activar área común</b>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};
};

export default CommonAreasEddit;
